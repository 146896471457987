import "./global.scss";
import { MessageOrId } from "./i18n";
import { initGlobalShortcuts } from "./utils/shortcuts";

export type DatadogActions = {
    track: <T extends string>(
        name: T extends `dd.action-name.${string}` ? never : T,
        options?: { [key: string]: unknown },
    ) => void;
    getName: (ddName?: string | MessageOrId) => string;
    reportError: (err: Error) => void;
};

// needs to be invoked by other UIs in order to use our components
export const setUpComponents = (actions?: DatadogActions) => {
    initGlobalShortcuts(actions);
    document.documentElement.setAttribute("scu-theme", "lidlWaWi");
};
