import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
// eslint-disable-next-line no-restricted-imports
import { createI18n } from "petite-vue-i18n";
import { setUpComponents } from "@ui/components";
import componentsFallbackMessages from "@ui/components/i18n/en_DEV.json";
import { authGuard } from "@ui/libs/auth";
import { WaWiLanguage } from "@ui/libs/i18n";
import libsFallbackMessages from "@ui/libs/i18n/en_DEV.json";
// eslint-disable-next-line no-restricted-imports
import { initClientConfig } from "@ui/libs/skeleton/clientConfig";
// eslint-disable-next-line no-restricted-imports
import { Datadog } from "@ui/libs/skeleton/datadog";
import App from "./App.vue";
import moduleFallbackMessages from "./i18n/en_DEV.json";
import { routes } from "./router";

type ComponentsMessages = typeof import("@ui/components/i18n/en_DEV.json");
type LibsMessages = typeof import("@ui/libs/i18n/en_DEV.json");
type UIMessages = typeof import("./i18n/en_DEV.json");
type AllMessages = ComponentsMessages & LibsMessages & UIMessages;
declare module "@ui/components" {
    interface Messages extends AllMessages {}
}

Datadog.init();

setUpComponents();

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});
initClientConfig();
const locale = WaWiLanguage.get() === "de-INT" ? "de" : WaWiLanguage.get();
const i18n = createI18n({
    locale,
    legacy: false,
    messages: {
        [locale]: {
            ...componentsFallbackMessages,
            ...libsFallbackMessages,
            ...moduleFallbackMessages,
        },
    },
});

router.beforeEach(authGuard);

const app = createApp(App).use(router).use(i18n);
app.mount("#app");
