<script lang="ts" setup>
import { onMounted, useTemplateRef } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import { DatadogActions } from "../../utils";
import { ShortcutUtil } from "../../utils/shortcuts";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import WuxShortcutKeys from "../WuxShortcuts/WuxShortcutKeys.vue";
import WuxSpinner from "../WuxSpinner/WuxSpinner.vue";
import { WuxTreeListItemProps } from "./WuxTreeList.core";

const props = defineProps<WuxTreeListItemProps>();
const emit = defineEmits<{ select: [event: KeyboardEvent | MouseEvent] }>();

const treeListItem = useTemplateRef("treeListItem");

const { optM, rawM } = useMsgFormatter();

onMounted(() => {
    if (!props.shortcut || !props.hasShortcutsEnabled) return;
    ShortcutUtil.useGlobal([
        props.shortcut,
        (e) => {
            e.stopPropagation();
            emit("select", e);
        },
    ]);
});

const select = (event: MouseEvent) => {
    if (props.isLoading || props.disabledMsg) return;
    emit("select", event);
};

defineExpose({
    focus: () => treeListItem.value?.focus(),
});
</script>

<template>
    <!-- An <a> tag is used here to enable right clicking to open in a new tab. However the default click event is stopped so normal clicks will be handled by custom logic. -->
    <a
        ref="treeListItem"
        class="wux-tree-list-item"
        :class="{
            'wux-tree-list-item--selected': props.isSelected,
            'wux-tree-list-item--disabled': props.disabledMsg,
            'wux-tree-list-item--loading': props.isLoading,
            'wux-tree-list-item--nested': props.items,
        }"
        :title="optM(props.disabledMsg)"
        :href="props.href"
        tabindex="-1"
        :data-dd-action="DatadogActions.getName(props.labelMsg || props.label)"
        @click.prevent.stop="select"
        @keydown.space.prevent.stop="emit('select', $event)"
        @keydown.enter.prevent.stop="emit('select', $event)"
    >
        <WuxIcon v-if="props.icon" :src="props.icon" />
        <span class="wux-tree-list-item__label">
            {{ rawM(props.labelMsg, props.label) }}
            <span class="wux-tree-list-item__description">
                {{ rawM(props.descriptionMsg, props.description) }}
            </span>
        </span>
        <WuxSpinner class="wux-tree-list-item__spinner" v-if="props.isLoading" type="component" />
        <div class="wux-tree-list-item__shortcut" v-if="props.shortcut && props.hasShortcutsEnabled">
            <WuxShortcutKeys v-if="props.shortcut" :shortcut="props.shortcut" inline />
        </div>
        <WuxIcon v-if="props.items" :src="Icons.chevron_right_small" />
    </a>
</template>

<style lang="scss">
@use "../../../assets/styles/mixins.scss";

.wux-tree-list-item {
    position: relative;

    display: flex;
    gap: 0.625rem;

    padding: 9px 10px; // 9px is used to display, with the maximum height, 7 entries (otherwise, the last would be only partially shown)

    overflow-wrap: anywhere;
    text-decoration: none;

    color: unset; // Unset the text color so it won't turn blue if a href is provided for the a tag
    @include mixins.hoverAndSelectedStates();
    @include mixins.focus-outline($inset: true);

    &:hover:not(.wux-tree-list-item--disabled) {
        cursor: pointer;
        // hack: duplicated class selector for higher specificity
        .wux-tree-list-item__shortcut.wux-tree-list-item__shortcut * {
            color: var(--wawi-color-grayscale-white);
        }
    }

    &__label {
        flex: 1;
        user-select: none;
    }
    &__description {
        display: block;
        color: var(--wawi-color-neutral-900);
        font-size: 13px;
        white-space: pre-line;
    }

    &__shortcut {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        font-size: 16px;
    }

    &:focus {
        background-color: var(--wawi-color-neutral-200);
    }

    &--nested {
        padding-right: 7px; //to align the chevrons to the shortcut keys
    }
    &--loading {
        background-color: var(--wawi-color-neutral-200);
        .wux-tree-list-item__spinner {
            backdrop-filter: none;
        }
    }
}
</style>
